<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card" style="z-index: 9999;">
        <div class="card-body">
            <div class="row">
                <div class="col-md-4 form-group" >
                    <label for="">Select you Currency</label>
                    <ng-select [items]="currencies"
                    bindLabel="name" 
                    bindValue="name" 
                    (change)="onChangeCurrency($event)"
                    [(ngModel)]="selectCurrency">
                    <ng-template ng-option-tmp let-item="item">
                        <img height="25" width="25" [src]="item.image"/>
                        {{item.name}}
                    </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="dailySummary && selectCurrency" style="z-index: 1;">
        <div class="card-body">
            <div class="row">
                <h4 class="mb-5">Daily Order Summary</h4>
                <div class="col-lg-3 col-sm-6 ">
                    <div class="card o-hidden widget-cards">
                        <div class="bg-warning card-body">
                            <div class="media static-top-widget row">
                                <div class="icons-widgets col-4">
                                    <div class="align-self-center text-center">
                                        <app-feather-icons [icon]="'trending-up'" class="font-warning"></app-feather-icons>
                                    </div>
                                </div>
                                <div class="media-body col-8"><span class="m-0">Pending</span>
                                    <h3 class="mb-0"><span class="counter">{{dailySummary.pending}}</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 ">
                    <div class="card o-hidden  widget-cards">
                        <div class="bg-secondary card-body">
                            <div class="media static-top-widget row">
                                <div class="icons-widgets col-4">
                                    <div class="align-self-center text-center">
                                        <app-feather-icons [icon]="'box'" class="font-secondary"></app-feather-icons>
                                    </div>
                                </div>
                                <div class="media-body col-8"><span class="m-0">Processing</span>
                                    <h3 class="mb-0"><span class="counter">{{dailySummary.processing}}</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 ">
                    <div class="card o-hidden widget-cards">
                        <div class="bg-primary card-body">
                            <div class="media static-top-widget row">
                                <div class="icons-widgets col-4">
                                    <div class="align-self-center text-center">
                                        <app-feather-icons [icon]="'package'" class="font-primary"></app-feather-icons>
                                    </div>
                                </div>
                                <div class="media-body col-8"><span class="m-0">Ready to Ship</span>
                                    <h3 class="mb-0"><span class="counter">{{dailySummary.readyToShip}}</span></h3>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 ">
                    <div class="card o-hidden widget-cards">
                        <div class="bg-danger card-body">
                            <div class="media static-top-widget row">
                                <div class="icons-widgets col-4">
                                    <div class="align-self-center text-center">
                                        <app-feather-icons [icon]="'truck'" class="font-danger"></app-feather-icons>
                                    </div>
                                </div>
                                <div class="media-body col-8"><span class="m-0">Shipped</span>
                                    <h3 class="mb-0"><span class="counter">{{dailySummary.shipped}}</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="orderSummary && selectCurrency">
        <div class="card-body">
            <div class="row">
                <h4 class="mb-5">Total Orders</h4>
                <div class="col-lg-3 col-sm-6 ">
                    <div class="card o-hidden widget-cards">
                        <div class="bg-warning card-body">
                            <div class="media static-top-widget row">
                                <div class="icons-widgets col-4">
                                    <div class="align-self-center text-center">
                                        <app-feather-icons [icon]="'trending-up'" class="font-warning"></app-feather-icons>
                                    </div>
                                </div>
                                <div class="media-body col-8"><span class="m-0">Pending</span>
                                    <h3 class="mb-0"><span class="counter">{{orderSummary.pending}}</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 ">
                    <div class="card o-hidden  widget-cards">
                        <div class="bg-secondary card-body">
                            <div class="media static-top-widget row">
                                <div class="icons-widgets col-4">
                                    <div class="align-self-center text-center">
                                        <app-feather-icons [icon]="'box'" class="font-secondary"></app-feather-icons>
                                    </div>
                                </div>
                                <div class="media-body col-8"><span class="m-0">Processing</span>
                                    <h3 class="mb-0"><span class="counter">{{orderSummary.processing}}</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 ">
                    <div class="card o-hidden widget-cards">
                        <div class="bg-primary card-body">
                            <div class="media static-top-widget row">
                                <div class="icons-widgets col-4">
                                    <div class="align-self-center text-center">
                                        <app-feather-icons [icon]="'package'" class="font-primary"></app-feather-icons>
                                    </div>
                                </div>
                                <div class="media-body col-8"><span class="m-0">Ready to Ship</span>
                                    <h3 class="mb-0"><span class="counter">{{orderSummary.readyToShip}}</span></h3>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 ">
                    <div class="card o-hidden widget-cards">
                        <div class="bg-danger card-body">
                            <div class="media static-top-widget row">
                                <div class="icons-widgets col-4">
                                    <div class="align-self-center text-center">
                                        <app-feather-icons [icon]="'truck'" class="font-danger"></app-feather-icons>
                                    </div>
                                </div>
                                <div class="media-body col-8"><span class="m-0">Shipped</span>
                                    <h3 class="mb-0"><span class="counter">{{orderSummary.shipped}}</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-past-six-month-orders *ngIf="selectCurrency" [selectCurrency]="selectCurrency"></app-past-six-month-orders>
</div>
<!-- Container-fluid Ends-->