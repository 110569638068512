import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../module/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'products',
    loadChildren: () => import('../../module/products/products.module').then(m => m.ProductsModule),
  },
  {
    path: 'qr-codes',
    loadChildren: () => import('../../module/qr-codes/qr-codes.module').then(m => m.QrCodesModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('../../module/orders/orders.module').then(m => m.OrdersModule),
  },
  {
    path: 'shipments',
    loadChildren: () => import('../../module/shipments/shipments.module').then(m => m.ShipmentsModule),
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
];