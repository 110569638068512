import { Component, OnDestroy, OnInit } from '@angular/core';
import Currency from 'src/app/shared/class/currency';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { SubSink } from 'subsink';
import { DailyOrderSummary } from '../../dto';

@Component({
  selector: 'app-orders-dashboard',
  templateUrl: './orders-dashboard.component.html',
  styleUrls: ['./orders-dashboard.component.scss']
})
export class OrdersDashboardComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  dailySummary: DailyOrderSummary;
  orderSummary: DailyOrderSummary;

  selectCurrency : string = 'GBP';

  currencies = Currency;

  constructor(
    private dashboardService : DashboardService
  ) { }

  ngOnInit() {
    this.getDailySummary();
    this.getOrderSummary();
  }

  onChangeCurrency(event : any) {
    this.selectCurrency = event?.name;
    if(event) {
      this.getDailySummary();
      this.getOrderSummary();
    }
  }

  getOrderSummary() {
    this.subs.add(
      this.dashboardService.orderSummary({currency : this.selectCurrency}).subscribe({
        next:(value) => {
            this.orderSummary = value;
        },
      })
    )
  }
  getDailySummary() {
    const today = new Date(); // Get the current date
    today.setHours(0, 0, 0, 0); // Set the time to the beginning of the day (midnight)
    
    this.subs.add(
      this.dashboardService.dailyOrderSummary({
        'today' : today,
        'currency' : this.selectCurrency
      }).subscribe({
        next:(value) => {
            this.dailySummary = value;
        },
      })
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }
}
