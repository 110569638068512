import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';

import { DashboardRoutingModule } from './dashboard-routing.module';

//Container
import { DashboardComponent } from './containers/dashboard/dashboard.component';
//Components
import { PastSixMonthOrdersComponent } from './components/past-six-month-orders/past-six-month-orders.component';
import { ShipmentsDashboardComponent } from './components/shipments-dashboard/shipments-dashboard.component';
import { OrdersDashboardComponent } from './components/orders-dashboard/orders-dashboard.component';
//Libraries
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { InventoryDashboardComponent } from './components/inventory-dashboard/inventory-dashboard.component';

@NgModule({
  declarations: [
    DashboardComponent, 
    PastSixMonthOrdersComponent, 
    OrdersDashboardComponent, 
    ShipmentsDashboardComponent, InventoryDashboardComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    FontAwesomeModule,
    NgApexchartsModule,
    NgxChartsModule,
    NgSelectModule,
    FormsModule,
    NgbModule
  ]
})
export class DashboardModule { }
