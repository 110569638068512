const Currency = [
    { 
        name: 'USD',
        image : "./assets/images/currency/dollar.png",
        currency : 'USD'
    }, 
    {
        name: 'GBP',
        image : "./assets/images/currency/pound-sterling.png",
        currency : 'GBP'
    }, 
    {
        name: 'EUR',
        image : "./assets/images/currency/euro.png",
        currency : 'EUR'
    }
]

export default Currency;