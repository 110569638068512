import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Set loading indicator before making the request
    this.authService.isLoading$.next(true);
    return next.handle(req).pipe(
      // Clear loading indicator after receiving the response
      finalize(() => this.authService.isLoading$.next(false))
    );
  }
}
