import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DashboardModule } from './module/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './module/auth/auth.module';
import { AuthService } from './shared/service/auth.service';
import { AuthInterceptor } from './shared/interceptor/auth-interceptor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpErrorInterceptor } from './shared/interceptor/error.interceptor';
import { LoadingInterceptor } from './shared/interceptor/loading.interceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DashboardModule,
    AuthModule,
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 1000000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    NgxSpinnerModule.forRoot({ type: 'ball-atom' }),
    FontAwesomeModule
  ],
  providers: [
    AuthService,
    JwtHelperService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
