<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-5 p-0 card-left">
                    <div class="card bg-primary">
                        <div class="svg-icon">
                            <img src="assets/logo/logo-512x512.png" alt="">
                        </div>
                        <div class="single-item">
                            <div>
                                <h3>Welcome!</h3>
                                <p>ConntectNok Admin Panel</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 p-0 card-right">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" 
                                        class="form-horizontal auth-form" novalidate="">
                                        <div class="form-group">
                                            <input required="" formControlName="email" type="email"
                                                class="form-control" placeholder="Email"
                                                id="exampleInputEmail1">
                                        </div>
                                        <div class="form-group">
                                            <input required="" formControlName="password" type="password"
                                                class="form-control" placeholder="Password">
                                        </div>
                                        <div class="form-terms">
                                            <div class="custom-control custom-checkbox d-flex align-items-center flex-wrap">
                                                <a href="javascript:void(0)"
                                                    class="btn btn-default forgot-pass ms-auto">Forgot password</a>
                                            </div>
                                        </div>
                                        <div class="form-button">
                                            <button class="btn btn-primary" type="submit">Login</button>
                                        </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>