import { Component } from '@angular/core';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { Product } from 'src/app/shared/service/product.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inventory-dashboard',
  templateUrl: './inventory-dashboard.component.html',
  styleUrls: ['./inventory-dashboard.component.scss']
})
export class InventoryDashboardComponent {
  private subs = new SubSink();

  public inventorySummary: { value: number, name: string, color: string, status: string }[] = [];

  public view: [number, number] = [400,400];
  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showXAxisLabel: boolean = true;
  yAxisLabel: string = 'Product';
  showYAxisLabel: boolean = true;
  xAxisLabel: string = 'Stock';

  colorScheme: Color = {
    domain: [],
    name: '',
    selectable: true,
    group: ScaleType.Linear
  };


  constructor(
    private dashboardService : DashboardService
  ) { }

  ngOnInit() {
    this.getInventorySummary()
  }

  getInventorySummary() {
    this.subs.add(
      this.dashboardService.inventorySummary().subscribe({
        next:(products: Product[]) => {        
          products.forEach(_product => {
            _product.variations.forEach(_variation => {
              let color = this.generateColor();
              this.inventorySummary.push({
                name: `${_product.name} (${_variation.name})`,
                value: _variation.stock,
                color: color,
                status: _variation.status
              });
              this.colorScheme.domain.push(color)

            })

            

          })
          
        },
      })
    )
  }

  generateColor(): string {
    const r = Math.floor(Math.random() * 256); // Random value between 0 and 255
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
  
    // Convert RGB values to hexadecimal color code
    const color = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  
    return color;
  }
}
