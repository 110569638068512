import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { DailyOrderSummary, DailyOrderSummaryRequest, MonthlyOrderCount, OrderSummaryRequest, PendingShipmentsSummaryResponseDto } from 'src/app/module/dashboard/dto';
import { Product } from './product.service';

const API_USERS_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  constructor(
    private httpClient : HttpClient
  ) { }

  dailyOrderSummary(request: DailyOrderSummaryRequest): Observable<DailyOrderSummary> {
    return this.httpClient.post<DailyOrderSummary>(`${API_USERS_URL}/dashboard/order-summary-by-day`, request) 
  }
  
  orderSummary(request: OrderSummaryRequest): Observable<DailyOrderSummary> {
    return this.httpClient.post<DailyOrderSummary>(`${API_USERS_URL}/dashboard/order-summary`, request)
  }

  pastSixMonthOrders(request: OrderSummaryRequest): Observable<MonthlyOrderCount[]> {
    return this.httpClient.post<MonthlyOrderCount[]>(`${API_USERS_URL}/dashboard/past-six-month-orders`, request)
  }
  
  pendingShipmentsSummary():Observable<PendingShipmentsSummaryResponseDto[]> {
    return this.httpClient.get<PendingShipmentsSummaryResponseDto[]>(`${API_USERS_URL}/dashboard/pending-shipments-summary`)
  }

  inventorySummary():Observable<Product[]> {
    return this.httpClient.get<Product[]>(`${API_USERS_URL}/dashboard/inventory-summary`)
  }
}
