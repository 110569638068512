<div class="row" *ngIf="inventorySummary.length > 0">
  <div class="col-md-12">
    <h3>Your Inventory</h3>
    <ngx-charts-bar-horizontal
      [scheme]="colorScheme"
      [results]="inventorySummary"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
    >
    </ngx-charts-bar-horizontal>
  </div>
  <div class="col-md-12 d-flex justify-content-center align-items-center">
    <div class="legend">
      <table>
        <tr *ngFor="let summary of inventorySummary">
          <td>
            <div
              class="orderColor"
              [ngStyle]="{ 'background-color': summary.color }"
            ></div>
          </td>
          <td>
            <h6 class="mb-0">{{ summary.name }}</h6>
          </td>
          <td>
            <span class="mb-0">{{ summary.value }}</span>
          </td>
          <td>
            <span
              class="pull-right fw-bolder text-success"
              [ngClass]="{ 'text-danger': summary.status === 'Inactive' }"
              >{{ summary.status }}</span
            >
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
