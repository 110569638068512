import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/service/auth.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: UntypedFormGroup;
  public registerForm: UntypedFormGroup;
  public active = 1;
  returnUrl: any;
  private subs = new SubSink();

  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router : Router,
    private notificationService : NotificationService) {
    
  }


  ngOnInit() {
    this.createLoginForm();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'.toString()] || '/';
  }


  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required)
    })
  }

  get formValues() {
    return this.loginForm.value
  }

  get formControls() {
    return this.loginForm.controls
  }




  onSubmit() {
    this.loginForm.markAllAsTouched();
    if(!this.formControls.email.valid) {
      this.notificationService.showNotification('Please provide a valid Email!');
      return;
    }
    if(!this.formControls.password.valid) {
      this.notificationService.showNotification('Password is required!');
      return;
    }

    this.subs.add(
      this.authService.login(this.formValues.email, this.formValues.password).subscribe({
        next:(res) => {
          this.router.navigate([this.returnUrl]);
        },
        error:(error) => {
          console.log(error)
        }
      })
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe();

  }
}
