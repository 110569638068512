import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

const TOKEN_HEADER_KEY = 'Authorization';

const API_USERS_URL = `${environment.apiUrl}`;

@Injectable()
export class AuthInterceptor implements HttpInterceptor{
  private isRefreshing = false;

  constructor(
    private authService : AuthService
  ) {}

  intercept(httpRequest: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
    if (httpRequest.url.includes(`${API_USERS_URL}/auth/login`)){
      return httpHandler.handle(httpRequest);
    }

    let authReq = httpRequest;

    const authToken : any = localStorage.getItem('authToken')
    const refreshToken : any = localStorage.getItem('refreshToken')
    const _token = JSON.parse(authToken)
    const _refreshToken = JSON.parse(refreshToken)

    if (_token != null && _refreshToken != null) {
      if(httpRequest.url.includes('auth/refresh') ) {
        authReq = this.addTokenHeader(httpRequest, _refreshToken);
      } else {
        authReq = this.addTokenHeader(httpRequest, _token);
      }
    }

    return httpHandler.handle(authReq).pipe(
      catchError(error => {
        // if this is really an http error
        if (
          error instanceof HttpErrorResponse &&
          !authReq.url.includes('auth/refresh') &&
          error.status === 401 && this.isRefreshing === false
        ) {
          return this.handle401Error(httpRequest, httpHandler);
        }

        return throwError(() => error);
     })
   );

  }

  private addTokenHeader(request: HttpRequest<any>, token: any) {
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
  }

  handle401Error(originalReq: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if(!this.isRefreshing) {
      this.isRefreshing = true;

      return this.authService.refreshToken().pipe(
        switchMap((response) => {
          localStorage.setItem('authToken', JSON.stringify(response.authToken));
          localStorage.setItem('refreshToken', JSON.stringify(response.refreshToken));
          this.isRefreshing = false;

          // Before sending the original request, attach the new auth token
          const newRequest = this.addTokenHeader(originalReq, response.authToken);

          // Retry the original request with the new token
          return next.handle(newRequest);

        }),
        catchError((error) => {
          this.isRefreshing = false;
          console.log(error)

          if (error.status == '401' || error.status == '403') {
            this.authService.logout();
          }

          return throwError(() => error);
        })
      )
    }
    return next.handle(originalReq);

  }
}
