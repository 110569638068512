<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-body tab2-card">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                          <a ngbNavLink>
                            <h4 class="fw-bolder">Orders</h4>
                          </a>
                          <ng-template ngbNavContent>
                            <app-orders-dashboard></app-orders-dashboard>
                          </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                          <a ngbNavLink>
                            <h4 class="fw-bolder">Shipments</h4>
                          </a>
                          <ng-template ngbNavContent>
                            <app-shipments-dashboard></app-shipments-dashboard>                            
                          </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                          <a ngbNavLink>
                            <h4 class="fw-bolder">Inventory</h4>
                          </a>
                          <ng-template ngbNavContent>
                            <app-inventory-dashboard></app-inventory-dashboard>
                          </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>