import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from './shared/service/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  title = 'multikart-backend';

  constructor(
    private authService : AuthService,
    private spinner: NgxSpinnerService,
    private cd : ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    this.authService.isLoading$.subscribe({
      next : (value) => {
          if(value === true) {
            this.spinner.show();
          } else {
            this.spinner.hide();
          }
      },
    })
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

}
