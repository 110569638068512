<div class="row" *ngIf="shipmentSummary.length === 0">
    <h3>No Pending Shipments Found!</h3>
</div>
<div class="row" *ngIf="shipmentSummary.length > 0">
  <div class="col-md-7">
    <h3>Required Inventory for Shipments</h3>
    <ngx-charts-pie-chart
      [scheme]="doughnutChartColorScheme"
      [view]="view"
      [results]="shipmentSummary"
      [explodeSlices]="false"
      [arcWidth]="0.5"
      [doughnut]="true"
    >
    </ngx-charts-pie-chart>
  </div>
  <div class="col-md-5 d-flex justify-content-center align-items-center">
    <div class="legend">
        <table>
            <tr *ngFor="let summary of shipmentSummary">
                <td>
                    <div class="orderColor" [ngStyle]="{ 'background-color': summary.color}"></div>
                </td>
                <td>
                    <h6 class="mb-0">{{summary.name}}</h6>
                </td>
                <td>
                    <span class="pull-right">{{summary.value}}</span>
                </td>
            </tr>
        </table>
    </div>
  </div>
</div>
