import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable, catchError, retry, throwError } from "rxjs";
import { NotificationService } from "../service/notification.service";

export class HttpErrorInterceptor implements HttpInterceptor {
    constructor() {
        
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const notificationService : NotificationService = inject(NotificationService)

        return next.handle(request).pipe(
            catchError((error : HttpErrorResponse) => {
                console.log(error)
                let errorMessage = "";
                console.log(error.error instanceof ErrorEvent)
                if(error.error instanceof ErrorEvent) {
                    errorMessage = `Error: ${error.error.message}`
                } else {
                    if(error.error.message) {
                        errorMessage = `Error: ${error.status} \nMessage: ${error.error.message}`
                    } else { 
                        errorMessage = `Error: ${error.status} \nMessage: ${error.message}`
                    }
                }
                console.log(errorMessage)
                notificationService.showNotification(errorMessage)
                return throwError(() => errorMessage);
            })
        )
    }

}