import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { SubSink } from 'subsink';
import { PendingShipmentsSummaryResponseDto } from '../../dto';
import { Color, ScaleType } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-shipments-dashboard',
  templateUrl: './shipments-dashboard.component.html',
  styleUrls: ['./shipments-dashboard.component.scss']
})
export class ShipmentsDashboardComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  public shipmentSummary : { value: number, name: string, color: string } [] = []
  public view: [number, number] = [400,400];
  public doughnutChartColorScheme: Color = {
    domain: [],
    name: '',
    selectable: true,
    group: ScaleType.Linear
  }

  constructor(
    private dashboardService : DashboardService
  ) { }

  ngOnInit() {
    this.getShipmentSummary()
  }

  getShipmentSummary() {
    this.subs.add(
      this.dashboardService.pendingShipmentsSummary().subscribe({
        next:(value: PendingShipmentsSummaryResponseDto[]) => {
          value.forEach(_data => {
            let color = this.generateColor();
            this.shipmentSummary.push({
              value: _data.qty,
              name: `${_data.productName} (${_data.variationName})`,
              color: color
            })
            this.doughnutChartColorScheme.domain.push(color)
          })
      },
      })
    )
  }

  generateColor(): string {
    const r = Math.floor(Math.random() * 256); // Random value between 0 and 255
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
  
    // Convert RGB values to hexadecimal color code
    const color = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  
    return color;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }
}
