import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-past-six-month-orders',
  templateUrl: './past-six-month-orders.component.html'
})
export class PastSixMonthOrdersComponent implements OnInit, OnDestroy, OnChanges {
  private subs = new SubSink();
  
  columnWithDataChart: any;

  @Input('selectCurrency') selectCurrency : string;

  dashboardDataSeries : any[] =[];
  dashboardDataCategory : any[] =[];
  
  constructor(
    private dashboardService : DashboardService
  ) {}

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    if ('selectCurrency' in changes) {
      this.getOrderSummaryByYear();
    }
  }
  getOrderSummaryByYear() {
    this.dashboardDataSeries = [];
    this.dashboardDataCategory = [];
    this.subs.add(
      this.dashboardService.pastSixMonthOrders({currency : this.selectCurrency}).subscribe({
        next:async (value) => {
            await value.forEach((element : any) => {
              this.dashboardDataSeries.push(element.orderCount);
              this.dashboardDataCategory.push(element.month);
            });
        
            this._columnWithDataChart('["#1f9ced"]');
        },
      })
    )
  }

  private _columnWithDataChart(colors:any) {
    colors = this.getChartColorsArray(colors);
    this.columnWithDataChart = {
      series: [{
        name: "Orders",
        data: this.dashboardDataSeries,
      }, ],
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
            show: false,
        },
      },
      plotOptions: {
          bar: {
              dataLabels: {
                  position: "top", // top, center, bottom
              },
          },
      },
      dataLabels: {
          enabled: true,
          offsetY: 2,
          style: {
              fontSize: "12px",
              colors: ["#ffffff"],
          },
      },
      colors: colors,
      grid: {
          borderColor: "#f1f1f1",
      },
      xaxis: {
          categories: this.dashboardDataCategory,
          position: "bottom",
          labels: {
              offsetY: 0,
          },
          axisBorder: {
              show: false,
          },
          axisTicks: {
              show: false,
          },
          crosshairs: {
              fill: {
                  type: "gradient",
                  gradient: {
                      colorFrom: "#D8E3F0",
                      colorTo: "#BED1E6",
                      stops: [0, 100],
                      opacityFrom: 0.4,
                      opacityTo: 0.5,
                  },
              },
          },
          tooltip: {
              enabled: true,
              offsetY: -35,
          },
      },
      fill: {
          gradient: {
              shade: "light",
              type: "horizontal",
              shadeIntensity: 0.25,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [50, 0, 100, 100],
          },
      },
      yaxis: {
          axisBorder: {
              show: false,
          },
          axisTicks: {
              show: false,
          },
      }
    };
  }

  private getChartColorsArray(colors:any) {
    colors = JSON.parse(colors);
    return colors.map(function (value:any) {
      var newValue = value.replace(" ", "");
      if (newValue.indexOf(",") === -1) {
        var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
            if (color) {
            color = color.replace(" ", "");
            return color;
            }
            else return newValue;;
        } else {
            var val = value.split(',');
            if (val.length == 2) {
                var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
                rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
                return rgbaColor;
            } else {
                return newValue;
            }
        }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

}
